:root {
  --text-font: "Noto Sans";

  --head-bg: #2e3436;
  --head-text: #eeeeec;
}

@custom-media --desktop (min-width: 700px);

html {
  font-family: var(--text-font);
}

body {
  margin: 0;
  padding: 0;
  font-size: 12pt;
  line-height: 1.4em;
}

/* top navigation */
.top-head {
  background-color: var(--head-bg);
  color: var(--head-text);
  padding: 0 1em;

  a:link, a:visited {
    color: var(--head-text);
  }

  h1 {
    font-size: 1rem;
  }
  ul {
    padding-left: 0;
  }
  ul li {
    list-style: circle;
    list-style-position: inside;
  }

  @media(--desktop) {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;

    h1 {
      display: flex;
      flex-grow: 0;
    }

    nav {
      flex-grow: 0;
      
      ul {
        display: flex;
        flex-direction: row;
        justify-content: end;
        width: 100%;

        li {
          text-align: right;
          display: block;
          list-style: none;
          padding: 0 1ex;
        }
      }
    }
  }
}

/* main content  */
.shell {
  padding: 1em;
}

/* body */
.shell article.main {
  max-width: 9in;
  margin: 0 auto;
}

/* footer */
footer {
  text-align: center;
  font-style: italic;
  font-size: 0.8rem;
  padding: 0 1rem 1rem;
}

/* home page banner */
.homepage {
  display: flex;
  width: 100%;
  max-width: 900px;
  margin: 0 auto;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;

  section, .element {
    width: 100%;
  }
  
  .banner {
    .logo {
      margin-top: 1em;
      width: 90%;
    }

    h1 {
      text-align: center;
    }

    .tagline {
      font-style: italic;
      font-size: 1.2rem;
      margin-top: 1em;
    }
  }

  @media(--desktop) {
    .banner {
      .logo {
        width: 5in;
      }
    }

    .offerings, .instructors, .related {
      width: 33%;
    }
  }
}

/* citations */
.citation {
  padding: 0 2em 0 4em;
  text-indent: -2em;
}

pre {
  padding-left: 2em;
}